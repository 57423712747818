import {Component, NgZone} from '@angular/core';
import { Router } from '@angular/router';

import { App, URLOpenListenerEvent } from '@capacitor/app';

import {AlertController, MenuController, NavController, Platform} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {FirebaseX} from '@awesome-cordova-plugins/firebase-x/ngx';
import {Device} from '@ionic-native/device/ngx';
import {ApiService} from './services/api.service';
import {Storage} from '@ionic/storage';
import {Network} from '@ionic-native/network/ngx';
import {Media, MediaObject} from '@ionic-native/media/ngx';
import {File} from '@awesome-cordova-plugins/file/ngx';
import {Deeplinks} from '@ionic-native/deeplinks/ngx';
// import {Geolocation, Geoposition} from '@ionic-native/geolocation/ngx';

import {Geoposition} from '@ionic-native/geolocation';

import { Geolocation } from '@capacitor/geolocation';

import {AuthService} from './services/auth.service';
import { OnResumeAppService } from './services/on-resume-app.service';
import { Subscription } from 'rxjs';

import {Events} from './services/events.service';

import { Bugfender } from '@bugfender/sdk';

declare var google;
declare var alert;
var alertObject;

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent {
    public appPages = [];
    public userPages = [
        // {
        //     title: 'Monster Spots',
        //     url: '/u/home',
        //     icon: 'home',
        //     image: 'assets/menu/MonsterSpots.svg'
        // },
        {
            title: 'Gift Cards',
            url: '/u/gift-cards',
            icon: 'gift',
            image: ''
        },
        {
            title: 'Rewards',
            url: '/u/rewards',
            icon: 'home',
            image: 'assets/menu/Rewards.svg'
        },
        // {
        //     title: 'My Favourites',
        //     url: '/u/my-favourites',
        //     icon: 'heart',
        //     image: 'assets/menu/Favourite.svg'
        // },
        // {
        //     title: 'Map',
        //     url: '/u/monster-spot',
        //     icon: 'navigate',
        //     image: 'assets/menu/Map.svg'
        // },
        // {
        //     title: 'Receipts',
        //     url: '/u/orders',
        //     icon: 'clipboard',
        //     image: 'assets/menu/Orders.svg'
        // },
        {
            title: 'Profile',
            url: '/u/profile',
            icon: 'person',
            image: 'assets/menu/profile.png'
        },
        {
            title: 'Manage Cards',
            url: '/u/manage-cards',
            icon: 'card',
            image: ''
        },
        {
            title: 'Address Book',
            url: '/u/address-book',
            icon: 'book',
            image: ''
        },
        {
            title: 'Contact Us',
            url: '/u/contact-us',
            icon: 'mail',
            image: 'assets/menu/contact.png'
        },

    ];

    public merchantPages = [
        {
            title: 'Home',
            url: '/merchant/merchant-home',
            icon: 'home',
            image: ''
        },
        {
            title: 'Reports',
            url: '/merchant/reports',
            icon: 'folder',
            image: ''
        },
        {
            title: 'Daily Orders',
            url: '/merchant/daily-orders',
            icon: 'clipboard',
            image: ''
        },
        {
            title: 'Tutorial',
            url: '/merchant/tutorial',
            icon: 'help-buoy',
            image: ''
        },
    ];
    autocompleteItems: any;
    GoogleAutocomplete: any;
    geocoder: any;

    isMerchant = false;
    networkAlert;

    tokenRefreshSubscriber;
    messageReceivedSubscriber;
    showLogin = true;

    lat = 0;
    lng = 0;


    constructor(
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        private device: Device,
        private storage: Storage,
        private apiService: ApiService,
        private alert: AlertController,
        // private datePicker: DatePicker,
        private navCtrl: NavController,
        // private zone: NgZone,
        // private global: Globals,
        // private menu: MenuController,
        private firebase: FirebaseX,
        // private router: Router,
        private events: Events,
        private network: Network,
        private media: Media,
        private file: File,
        private zone: NgZone,
        private deeplinks: Deeplinks,
        // private geoLocation: Geolocation,
        private auth:AuthService,
        private router: Router,
        private menu: MenuController,
        private onResumeApp: OnResumeAppService
    ) {

        this.events.subscribe('loginTasks', (data) => {
            console.log('logintasks');
            this.showLogin = false;
            this.loginTasks();
            let user = data.user;

            //DOESNT WORK BECAUSE HASNT LOADED YET
            if (user.role == 'client') {
                // console.log(user.profile_complete)
                //Make sure their profile is complete!/
                // this.events.publish('user:profile_complete',user.profile_complete =="1" ? true : false)
                this.appPages = this.userPages;
                this.isMerchant = false;
            }

            if (user.role == 'merchantBarista' || user.role == 'merchant') {
                this.appPages = this.merchantPages;
                this.isMerchant = true;
            }
        });
        this.appPages = this.userPages;
        this.initializeApp();
    }

    initializeApp() {
        // this.auth.updateState().then(()=>{
        //     this.showLogin = false;
        // })

        App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
            this.zone.run(() => {
                // Example url: https://beerswift.app/tabs/tab2
                // slug = /tabs/tab2
                const domain = '.com';

                const slug = event.url.split(domain).pop();
                console.log('deep routing');
                console.log(slug);

                if (slug) {
                    this.router.navigateByUrl(slug);
                }
                // If no match, do nothing - let regular routing
                // logic take over
            });
        });

        this.platform.pause.subscribe(async () => {
            console.log('paused')
        })

        this.storage.get('accessToken').then((token) => {
            if(token) {
              this.showLogin = false;
            }
        });

        this.platform.resume.subscribe(async () => {
            console.log('resumed');
            const doNotGoToOrders = this.onResumeApp.doNotGoToOrdersOnResume.value;
            this.zone.run(() => {
              if (!doNotGoToOrders) {
                this.storage.get('onOrder').then((onOrder) => {
                  if (onOrder) {
                      this.router.navigate(['/u/orders'],  { queryParams: { openProcessingOrder: 1 } });
                      // this.events.publish('getOrders');
                      // this.navCtrl.navigateRoot(['/u/orders']).then(() => {
                      //     this.events.publish('closeOrderModal', {});
                      // });
                  }
                });
              }
              if (this.isMerchant) {
                this.router.navigate(['/splash']);
              }
            });

        });

        this.platform.ready().then(() => {
            this.statusBar.styleDefault();
            this.splashScreen.hide();

            Bugfender.init({
                appKey: 'HbtWptvrqtsuNn5rYP0wLmGdJLUgdMXU',
                overrideConsoleMethods: true,
                printToConsole: true,
                registerErrorHandler: true,
                version: '1.2.1',
                // build: '',
            });


            setTimeout(() => {
                this.GoogleAutocomplete = new google.maps.places.AutocompleteService();
                this.geocoder = new google.maps.Geocoder;
            }, 400);


            this.monitorNetworkConnection();

            this.autocompleteItems = [];
            //if this is merchant

            this.deeplinks.route({
                '/store/:id': 'store',
                // '/test': 'store',
            }).subscribe(match => {
                // match.$route - the route we matched, which is the matched entry from the arguments to route()
                // match.$args - the args passed in the link
                // match.$link - the full link data
                console.log(match.$args);
                console.log(match.$route);
                console.log(match.$link);

                // if (match.$route == 'store') {
                //     let id = match.$args.id;
                // }

                this.storage.get('lat').then((lat) => {
                  if(lat) {
                    this.lat = lat;
                  } else {
                    this.lat = 0;
                  }

                  this.storage.get('lng').then((lng) => {
                    if(lng) {
                      this.lng = lng;
                    } else {
                      this.lng = 0;
                    }

                    this.apiService.fetchStores(this.lat, this.lng, false).then((merchants: any) => {
                        console.log(merchants);
                        console.log(match.$args.id);
                        let onlineMerchants = merchants.coffee;
                        let found = false;
                        onlineMerchants.forEach(merchant => {
                            if (merchant.id == match.$args.id) {
                                found = true;
                                // console.log(merchant);
                                // console.log(match.$args.id);
                                this.storage.set('merchant', merchant).then(() => {
                                    this.navCtrl.navigateForward(['/store']);
                                });
                            }
                        });

                        if (!found) {
                            this.alert.create({
                                header: 'Store Not Found',
                                message: 'The store is currently unavailable',
                                buttons: [
                                    {
                                        text: 'OK',
                                        role: 'cancel',
                                        // cssClass: 'secondary',
                                        handler: () => {

                                        }
                                    }],
                            }).then((al) => {
                                al.present();

                            });
                        }

                    });
                  });
                });

                console.log('Successfully matched route', match);
                // this.navCtrl.navigateRoot(match.$route);
            }, nomatch => {
                // nomatch.$link - the full link data
                console.error('Got a deeplink that didn\'t match', nomatch);
            });

        });


    }

    loginTasks() {
        console.log("performing login tasks");
        this.storage.set('onboardingComplete', true).then(() => {

        });

        if (this.device.uuid) {
            this.apiService.updateDeviceId(this.device.uuid).then(() => {

            });
        }


        this.firebase.hasPermission().then((hasPermission) => {
            if (!hasPermission) {
              console.log("Permissions not set - asking now");
                this.firebase.grantPermission().then((hasPermission) => {
                    console.log('Permission was ' + (hasPermission ? 'granted' : 'denied'));
                    if (hasPermission) {
                        this.startFirebase();
                    }
                });
            } else {
                this.startFirebase();
            }
        });

        if (this.isMerchant) {
            //Subscribe to the merchant database
        }


    }

    startFirebase() {
        this.firebase.getToken().then(
            (token) => {
                console.log("FIREBASE TOKEN");
                console.log(token);
            })
            .catch(error => console.error('Error getting token', error));


        this.messageReceivedSubscriber = this.firebase.onMessageReceived()
            .subscribe(
                (data) => {
                    let title = data['title'];
                    let message = data['body'];
                    let type = data['type'];
                    let tap = false;
                    if (data['tap']) {
                        tap = true;
                    }
                    let buttons = [];
                    switch (type) {
                        case 'got_loyalty' :
//                             this.storage.get('accessToken').then((token) => {
//                                 this.apiService.validateToken(token).then((user: any) => {
//
//                                     this.storage.set('user', user).then(() => {
//
//                                     });
//                                 });
//                             });
                            buttons = [
                                {
                                    text: 'OK',
                                    role: 'cancel',
                                    handler: () => {

                                    }
                                }];

                            break;
                        case 'order_cancelled' :
                            buttons = [
                                {
                                    text: 'OK',
                                    role: 'cancel',
                                    handler: () => {
                                    }
                                }];
                            break;
                        case 'update_location' :
                            buttons = [
                                {
                                    text: 'OK',
                                    role: 'cancel',
                                    handler: () => {
                                    }
                                }];
                            break;
                        case 'urgent_order' :
                            buttons = [
                                {
                                    text: 'OK',
                                    role: 'cancel',
                                    // cssClass: 'secondary',
                                    handler: () => {
                                    }
                                }];
                            break;
                        case 'favourite_removed' :
                            buttons = [
                                {
                                    text: 'OK',
                                    role: 'cancel',
                                    handler: () => {
                                    }
                                }];
                            break;
                        case 'new_order' :
                            tap = true;
                            this.events.publish('fireBaseOrders');
                            buttons = [
                                {
                                    text: 'OK',
                                    role: 'cancel',
                                    handler: () => {
                                    }
                                }];
                            break;
                        case 'merchant_viewed' :
                            this.events.publish('getOrders');
                            buttons = [
                                {
                                    text: 'OK',
                                    role: 'cancel',
                                    handler: () => {
                                      let orderId = data['order_id'] || '';
                                      if (orderId) {
                                        this.router.navigate(['/u/orders'],  { queryParams: { orderId: orderId } }).then(() => {
                                            this.events.publish('closeOrderModal', {});
                                        });
                                      } else {
                                        this.router.navigate(['/u/orders']).then(() => {
                                            this.events.publish('closeOrderModal', {});
                                        });
                                      }
                                    }
                                }];
                            break;
                        case 'merchant_accepted' :
                            this.events.publish('getOrders');
                            buttons = [
                                {
                                    text: 'OK',
                                    role: 'cancel',
                                    handler: () => {
                                      let orderId = data['order_id'] || '';
                                      if (orderId) {
                                        this.router.navigate(['/u/orders'],  { queryParams: { orderId: orderId } }).then(() => {
                                            this.events.publish('closeOrderModal', {});
                                        });
                                      } else {
                                        this.router.navigate(['/u/orders']).then(() => {
                                            this.events.publish('closeOrderModal', {});
                                        });
                                      }
                                    }
                                }];
                            break;
                        case 'merchant_cancelled' :
                            this.events.publish('getOrders');
                            buttons = [
                                {
                                    text: 'OK',
                                    role: 'cancel',
                                    handler: () => {
                                      let orderId = data['order_id'] || '';
                                      if (orderId) {
                                        this.router.navigate(['/u/orders'],  { queryParams: { orderId: orderId } }).then(() => {
                                            this.events.publish('closeOrderModal', {});
                                        });
                                      } else {
                                        this.router.navigate(['/u/orders']).then(() => {
                                            this.events.publish('closeOrderModal', {});
                                        });
                                      }
                                    }
                                }];
                            break;
                        case 'ready_for_collection' :
                            this.events.publish('getOrders');
                            buttons = [
                                {
                                    text: 'OK',
                                    role: 'cancel',
                                    handler: () => {
                                      let orderId = data['order_id'] || '';
                                      if (orderId) {
                                        this.router.navigate(['/u/orders'],  { queryParams: { orderId: orderId } }).then(() => {
                                            this.events.publish('closeOrderModal', {});
                                        });
                                      } else {
                                        this.router.navigate(['/u/orders']).then(() => {
                                            this.events.publish('closeOrderModal', {});
                                        });
                                      }
                                    }
                                }];
                            break;
                        case 'rate_review' :
                            this.events.publish('getOrders');
                            buttons = [
                                {
                                    text: 'OK',
                                    // role: 'cancel',
                                    handler: () => {
                                        this.navCtrl.navigateRoot(['/u/orders']).then(() => {
                                            this.events.publish('rateMerchant', data);
                                        });
                                    }
                                },
                                {
                                    text: 'Dismiss',
                                    // role: 'cancel',
                                    cssClass: 'secondary',
                                    handler: () => {
                                    }
                                }
                                ];
                            break;
                        case 'free_coffee' :
                            buttons = [
                                {
                                    text: 'OK',
                                    // role: 'cancel',
                                    handler: () => {

                                    }
                                }];
                            break;
                        case 'next_free_coffee' :
                            buttons = [
                                {
                                    text: 'OK',
                                    // role: 'cancel',
                                    handler: () => {

                                    }
                                }];
                            break;
                    }


                    if (!tap) {
                        this.alert.create({
                            header: title,
                            message: message,
                            buttons: buttons,
                        }).then((al) => {
                            al.present();

                        });
                    }

                }
            );


        this.tokenRefreshSubscriber = this.firebase.onTokenRefresh()
            .subscribe((token: string) => {
                this.apiService.setFcmDevice(token, this.device.platform).then(
                    (response: any) => {
                        this.storage.set('firebase_token', token);
                    }).catch((error) => {
                    console.log('Error', error);
                });

                // console.log(`Got a new token ${token}`)
            });

        this.firebase.setBadgeNumber(0);
    }


    monitorNetworkConnection() {
        // alert = this.alert;
        // alert.create({
        //     animated: false,
        //     header: 'Internet Connection Lost',
        //     message: 'Please re-establish the connection or wait a moment for the connection to stabilize.',
        //     backdropDismiss: false
        // }).then((al) => {
        //     al.present();
        //     alertObject = al;
        //     alertObject.dismiss();
        // });

        this.networkAlert = null;
        this.network.onDisconnect().subscribe(() => {
          this.alert.create({
              animated: false,
              header: 'Internet Connection Lost',
              message: 'Please re-establish the connection or wait a moment for the connection to stabilize.',
              backdropDismiss: true
          }).then((al) => {
              al.present();
              alertObject = al;
          });
        });
        this.network.onConnect().subscribe(() => {
          if (alertObject) {
            alertObject.dismiss();
          }
        });
        // this.network.onChange().subscribe((event) => {
        //   setTimeout(() => {
        //     if (event.type == 'online') {
        //       if (alertObject) {
        //         alertObject.dismiss();
        //       }
        //     }
        //     if (event.type == 'offline') {
        //         this.alert.create({
        //             animated: false,
        //             header: 'Internet Connection Lost',
        //             message: 'Please re-establish the connection or wait a moment for the connection to stabilize.',
        //             backdropDismiss: false
        //         }).then((al) => {
        //             al.present();
        //             alertObject = al;
        //         });
        //     }
        //   }, 3000);
        // });
    }

    logout() {

        if (this.tokenRefreshSubscriber){
            this.tokenRefreshSubscriber.unsubscribe();
        }
        if (this.messageReceivedSubscriber){
            this.messageReceivedSubscriber.unsubscribe();
        }

        this.firebase.unregister().then(() => {
            this.storage.clear().then(res => {
                this.auth.updateState().then(()=>{
                    this.showLogin = true;
                    this.navCtrl.navigateRoot('/u/home');
                })
            });
        });
    }

    login(){
        this.navCtrl.navigateForward('/login');

    }

    menuChanged(item) {
        if (!this.isMerchant) {
            this.events.publish('user:title', item.title);
        }
        if(item.url === '/u/home') {
          this.router.navigate(['/u/home'], { queryParams: { nonFilteredShops: 1 } });
        }
    }

    tutorial() {
        window.open('https://coffeemonster.co.za/merchant-how-to-guide/', '_blank');
    }

    closeMenu() {
      this.menu.close();
    }

    ngOnDestroy() {
        console.log('destructionApp');
        // this.events.unsubscribe('loginTasks');

    }
}
