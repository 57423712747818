import {Injectable, NgZone} from '@angular/core';
import {AlertController, LoadingController, NavController} from '@ionic/angular';
import {Storage} from '@ionic/storage';
import {
    MOCO_APISERVICE_AUTH,
    MOCO_APISERVICE_BASE_URL,
    MOCO_APISERVICE_BASE_URL2,
    MOCO_APISERVICE_URL
} from '../../environments/environment';
// import {HTTP} from '@awesome-cordova-plugins/http/ngx';
import { Http } from '@capacitor-community/http';

import { IonLoaderService } from './ion-loader.service';

@Injectable({
    providedIn: 'root'
})
export class ApiService {

    urlApi: string;
    urlApi2: string;
    token: any;
    requestMessageIdentifier: string;
    previousIdentifier: string;
    isLoading = false;

    constructor(public zone: NgZone,
                // private http: HTTP,
                public storage: Storage,
                private loadingController: LoadingController,
                private alertController: AlertController,
                private navCtrl: NavController,
                private ionLoaderService: IonLoaderService
    ) {
        this.urlApi = MOCO_APISERVICE_BASE_URL;
        this.urlApi2 = MOCO_APISERVICE_BASE_URL2;
    }

    hideLoader() {
      this.ionLoaderService.dismissLoader();
    }
    showLoader() {
      this.ionLoaderService.customLoader();
    }

    private ajax(url, verb, parameters, loader = true) {
        console.log('prev:', this.previousIdentifier);
        console.log(this.previousIdentifier);
        console.log('now:', this.requestMessageIdentifier);
        console.log(this.requestMessageIdentifier);
        return new Promise((resolve, reject) => {
            if (loader) {
                this.showLoader()

                return this.call(url, verb, parameters).then((response) => {
                    this.hideLoader();

                    resolve(response);

                }).catch((err) => {
                  this.hideLoader();

                  this.errorHandler(err);
                  reject(err);
                });
            } else {
                return this.call(url, verb, parameters).then((response) => {
                    resolve(response);
                }).catch((err) => {
                    this.errorHandler(err);
                    reject(err);
                });
            }

        });

    }

    private call(url, verb, parameters) {
        // console.log(url);
        // console.log(verb);

        if (parameters == null) {
            parameters = {};
        }
        // console.log(JSON.stringify(parameters));
        return this.storage.get('accessToken').then(token => {
            this.token = token;
            this.previousIdentifier = this.requestMessageIdentifier;
            // this.http.setDataSerializer('json');
            let headers = {
                'Content-Type': 'application/json'
            };
            if (this.token !== undefined && this.token !== null) {
                headers['Authorization'] = 'Bearer ' + this.token;
            }
            return new Promise((resolve, reject) => {
              console.log("Calling: "+this.requestMessageIdentifier);
              var currentTime = Math.floor(Date.now() / 1000);

              if(verb == "GET") {
                const options = {
                  url: url,
                  headers: headers,
                  params: parameters,
                  method: verb.toLowerCase(),
                };

                return Http.request(options).then(
                    response => {
                        console.log(response.data);
                        var completeTime = Math.floor(Date.now() / 1000);
                        var secondsToComplete = completeTime - currentTime;
                        console.log(this.requestMessageIdentifier + " complete in " + secondsToComplete + " seconds");
                        resolve(response.data);
                    },
                    err => {
                        reject(err);
                    }
                );
              } else {
                const options = {
                  url: url,
                  headers: headers,
                  data: parameters,
                  method: verb.toLowerCase(),
                };

                  return Http.request(options).then(
                      response => {
                          console.log(response.data);
                          var completeTime = Math.floor(Date.now() / 1000);
                          var secondsToComplete = completeTime - currentTime;
                          console.log(this.requestMessageIdentifier + " complete in " + secondsToComplete + " seconds");
                          resolve(response.data);
                      },
                      err => {
                          reject(err);
                      }
                  );
              }

            });
        });

    }

    private errorHandler(err) {
        console.log(err);
        // console.log(err.error.message)
        if (err.status !== 401 && this.requestMessageIdentifier !== 'validateToken') {
            err = JSON.parse(err.error);
            let message = err.message;
            this.alertController.create({
                header: 'Error',
                message: message,
                buttons: [{text: 'Dismiss'}]
            }).then((res) => {
                res.present();
            });
        } else {
          err = JSON.parse(err.error);
          let message = err.message;
          this.alertController.create({
              header: 'Error',
              message: message,
              buttons: [{text: 'Dismiss'}]
          }).then((res) => {
              res.present();
          });
        }
        // if (err.status == 401 && this.requestMessageIdentifier !== 'validateToken' && this.requestMessageIdentifier !== 'login') {
        //     this.storage.clear().then(result => {
        //         this.navCtrl.navigateRoot('/');
        //     });
        // }
    }

    private Get(url, parameters, loader = true) {
        return this.ajax(url, 'GET', parameters, loader);
    }

    private Post(url, parameters, loader = true) {
        return this.ajax(url, 'POST', parameters, loader);
    }

    private Put(url, parameters, loader = true) {
        return this.ajax(url, 'PUT', parameters, loader);
    }

    private Delete(url, parameters, loader = true) {
        return this.ajax(url, 'DELETE', parameters, loader);
    }

    validateToken(token) {
        this.requestMessageIdentifier = 'validateToken';
        return this.Post(this.urlApi2 + 'users/validate_token', {token: token}, false);
    };

    login(credentials) {
        this.requestMessageIdentifier = 'login';
        return this.Post(MOCO_APISERVICE_AUTH + 'token', credentials);
    };

    facebookConnect(fbObject) {
        this.requestMessageIdentifier = 'facebookConnect';
        return this.Post(this.urlApi2 + 'users/facebook_connect', fbObject);
    };

    register(regData) {
        this.requestMessageIdentifier = 'register';
        return this.Post(MOCO_APISERVICE_BASE_URL + 'user/register', regData);
    };

    getTokenRegister(regData) {
        this.requestMessageIdentifier = 'getTokenRegister';
        return this.Post(MOCO_APISERVICE_BASE_URL + 'user/getTokenRegister', regData, false);
    };

    getTerms() {
        this.requestMessageIdentifier = 'getTerms';
        return this.Get(MOCO_APISERVICE_BASE_URL + 'user/get_terms', null);
    };

    forgotPassword(reset) {
        this.requestMessageIdentifier = 'forgotPassword';
        return this.Post(MOCO_APISERVICE_BASE_URL + 'user/forgot_password', reset);
    };

    forgotPasswordValidate(reset) {
        this.requestMessageIdentifier = 'forgotPasswordValidate';
        return this.Post(MOCO_APISERVICE_BASE_URL + 'user/forgot_password_update', reset);
    };

    // getProfile () {
    //   this.requestMessageIdentifier = 'getProfile';
    //   return this.Get(MOCO_APISERVICE_BASE_URL2 + "users/get_profile",null);
    // };

    getRewards(loader=true) {
        this.requestMessageIdentifier = 'getRewards';
        return this.Get(this.urlApi2 + 'users/get_rewards', null,loader);
    };

    updateProfile(profile) {
        this.requestMessageIdentifier = 'updateProfile';
        return this.Post(this.urlApi2 + 'users/update', profile);
    };

    // getCards(loader=true) {
    //     this.requestMessageIdentifier = 'getCards';
    //     return this.Get(MOCO_APISERVICE_BASE_URL + 'user/cards_updated', null,loader);
    // };

    getCards(loader=true) {
        this.requestMessageIdentifier = 'getCards';
        return this.Get(MOCO_APISERVICE_BASE_URL + 'user/cards', null,loader);
    };

    // addCard() {
    //     this.requestMessageIdentifier = 'addCard';
    //     return this.Post(MOCO_APISERVICE_BASE_URL + 'user/new_card_updated', null);
    // };

    addCard(order) {
        this.requestMessageIdentifier = 'addCard';
        return this.Post(MOCO_APISERVICE_BASE_URL + 'user/new_card', null);
    };

    deleteCard(card) {
        this.requestMessageIdentifier = 'deleteCard';
        return this.Post(MOCO_APISERVICE_BASE_URL + 'user/delete_card', card);
    };

    getFavourites(lat, lng) {
        this.requestMessageIdentifier = 'getFavourites';
        return this.Post(this.urlApi2 + 'users/get_favourites', {lat: lat, lng: lng});
    };

    getFavouriteMerchants(lat, lng) {
        this.requestMessageIdentifier = 'getFavouriteMerchants';
        return this.Post(this.urlApi2 + 'users/get_favourite_merchants', {lat: lat, lng: lng});
    };

    getFavouriteMerchantOrders(merchantId) {
        this.requestMessageIdentifier = 'getFavouriteMerchantOrders';
        return this.Post(this.urlApi2 + 'users/get_favourite_orders_updated', { merchant_id: merchantId });
    };

    getUserOrders(loader = true) {
        this.requestMessageIdentifier = 'getUserOrders';
        return this.Get(this.urlApi2 + 'users/get_orders', null, loader);
    };

    fetchStores(lat, lng, group, loader = true) {
        this.requestMessageIdentifier = 'fetchStores';
        return this.Post(this.urlApi2 + 'store/stores', {lat: lat, lng: lng, group: group});
    };

    fetchSingleStore(store) {
        this.requestMessageIdentifier = 'fetchSingleStore';
        return this.Post(this.urlApi2 + 'store/singleStore', store);
    };

    fetchBoundedStores(n, e, s, w, lat, lng) {
        this.requestMessageIdentifier = 'fetchBoundedStores';
        return this.Post(this.urlApi2 + 'store/stores', {n: n, e: e, s: s, w: w, lat: lat, lng: lng});
    };

    fetchStoreProducts(id) {
        this.requestMessageIdentifier = 'fetchStoreProducts';
        return this.Get(this.urlApi2 + 'store/view/' + id, null);
    };

    // placeOrder(order) {
    //     this.requestMessageIdentifier = 'placeOrder';
    //     return this.Post(this.urlApi2 + 'store/new_order_updated', order);
    // };

    placeOrder(order) {
        this.requestMessageIdentifier = 'placeOrder';
        return this.Post(this.urlApi2 + 'store/new_order', order);
    };

    applyCoupon(loader=false, order) {
        this.requestMessageIdentifier = 'applyCoupon';
        return this.Post(this.urlApi2 + 'store/apply_coupon', order);
    };

    getMerchantOrders(id) {
        this.requestMessageIdentifier = 'getMerchantOrders';
        return this.Get(this.urlApi2 + 'merchant/orders', null, false);
    };

    updateMerchantOrderStatus(id, status) {
        this.requestMessageIdentifier = 'updateMerchantOrderStatus';
        return this.Post(this.urlApi2 + 'merchant/update_temp/' + id, {
            status: status
        }, false);
    };

    setFcm(token) {
        this.requestMessageIdentifier = 'setFcm';
        return this.Post(MOCO_APISERVICE_BASE_URL + 'user/update_fcm?token=' + token, null);
    };

    setFcmDevice(token, device) {
        this.requestMessageIdentifier = 'setFcmDevice';
        return this.Post(this.urlApi2 + 'users/update_fcm_device', {
            token: token,
            device: device,
        });
    };

    updateLocation(location) {
        this.requestMessageIdentifier = 'updateLocation';
        return this.Post(MOCO_APISERVICE_BASE_URL + 'store/update_order_location', location);
    };

    getOrder(id) {
        this.requestMessageIdentifier = 'getOrder';
        return this.Get(MOCO_APISERVICE_BASE_URL + 'merchant/view_order/' + id, null);
    };

    rateReview(rating) {
        this.requestMessageIdentifier = 'rateReview';
        return this.Post(MOCO_APISERVICE_BASE_URL + 'user/rate_order_merchant', {
            merchant_id: rating.merchant_id,
            order_id: rating.order_id,
            stars: rating.stars,
            comment: rating.comment
        });
    };

    rateAppOnce(rating) {
        this.requestMessageIdentifier = 'rateAppOnce';
        return this.Post(MOCO_APISERVICE_BASE_URL + 'user/rate_app', {
            order_id: rating.order_id,
        });
    };

    sendMessage(msg) {
        this.requestMessageIdentifier = 'sendMessage';
        return this.Post(MOCO_APISERVICE_BASE_URL + 'user/send_contact_message', {message: msg});
    };

    updateDeviceId(uuid) {
        this.requestMessageIdentifier = 'updateDeviceId';
        return this.Post(MOCO_APISERVICE_BASE_URL + 'user/update_device_id', {uuid: uuid}, false);
    };

    merchantGoOnline() {
        this.requestMessageIdentifier = 'merchantGoOnline';
        return this.Post(MOCO_APISERVICE_BASE_URL + 'merchant/go_online', null, false);
    };

    merchantGoOffline() {
        this.requestMessageIdentifier = 'merchantGoOffline';
        return this.Post(MOCO_APISERVICE_BASE_URL + 'merchant/go_offline', null, false);
    };

    merchantIsOnline() {
        this.requestMessageIdentifier = 'merchantIsOnline';
        return this.Post(MOCO_APISERVICE_BASE_URL + 'merchant/get_online', null, false);
    };

    updateAvatar(encodedPhoto) {
        this.requestMessageIdentifier = 'updateAvatar';
        return this.Post(MOCO_APISERVICE_BASE_URL + 'user/upload_avatar', {photo: encodedPhoto});
    };

    getLoyalty(order) {
        this.requestMessageIdentifier = 'getLoyalty';
        return this.Post(MOCO_APISERVICE_BASE_URL + 'store/get_loyalty', {cart: order, tip: order.tip});
    };

    removeFavourite(order_id) {
        this.requestMessageIdentifier = 'removeFavourite';
        return this.Post(this.urlApi2 + 'users/remove_favourite', {id: order_id});
    };

    runReport(req) {
        this.requestMessageIdentifier = 'runReport';
        return this.Post(this.urlApi2 + 'merchant/report', req);
    };

    dailyOrders() {
        this.requestMessageIdentifier = 'dailyOrders';
        return this.Get(MOCO_APISERVICE_BASE_URL + 'merchant/daily_orders', null);
    };

    fetchMerchantTutorial() {
        this.requestMessageIdentifier = 'fetchMerchantTutorial';
        return this.Get(MOCO_APISERVICE_URL + 'wp-json/wp/v2/pages?_embed&slug=barrista-service-on-the-go', {});
    };

    updateNoPickupOrderStatus(details) {
        this.requestMessageIdentifier = 'updateNoPickupOrderStatus';
        return this.Post(this.urlApi2 + 'merchant/order_not_collected', details);
    };

    orderStatusCheck() {
        this.requestMessageIdentifier = 'orderStatusCheck';
        return this.Get(MOCO_APISERVICE_BASE_URL + 'user/order_status_check', null);
    };

    logout() {
        this.requestMessageIdentifier = 'logout';
        return this.Post(MOCO_APISERVICE_BASE_URL + 'user/logout', null);
    };

    cancelOrder(cancelOrder) {
        this.requestMessageIdentifier = 'cancelOrder';
        return this.Post(this.urlApi2 + 'users/cancel_order', cancelOrder);
    };

    imHere(order) {
        this.requestMessageIdentifier = 'imHere';
        return this.Post(this.urlApi2 + 'users/im_here', order);
    };

    getAddresses(loader=true) {
        this.requestMessageIdentifier = 'getAddresses';
        return this.Get(this.urlApi2 + 'users/get_addresses', {},loader);
    };

    addAddress(address) {
        this.requestMessageIdentifier = 'addAddress';
        return this.Post(this.urlApi2 + 'users/add_address', address);
    };

    removeAddress(address) {
        this.requestMessageIdentifier = 'removeAddress';
        return this.Post(this.urlApi2 + 'users/remove_address', address);
    };

    getGiftCardBalance(loader = true) {
        this.requestMessageIdentifier = 'getGiftCardBalance';
        return this.Get(this.urlApi2 + 'users/get_gift_card_balance', null, loader);
    };

    claimGiftCode(code,loader = true) {
        this.requestMessageIdentifier = 'claimGiftCode';
        return this.Post(this.urlApi2 + 'users/claim_gift_code', {code:code}, loader);
    };

    getGiftCardProducts(loader = true) {
        this.requestMessageIdentifier = 'getGiftCardProducts';
        return this.Get(this.urlApi2 + 'users/get_gift_card_products', null, loader);
    };

    buyGiftCard(cardData,loader = true) {
        this.requestMessageIdentifier = 'buyGiftCard';
        return this.Post(this.urlApi2 + 'users/buy_gift_card', cardData, loader);
    };

    appleConnect(appleObject) {
        this.requestMessageIdentifier = 'appleConnect';
        return this.Post(this.urlApi2 + 'users/apple_connect', appleObject);
    };

    deleteAccount(email, loader=true) {
        this.requestMessageIdentifier = 'deleteAccount';
        return this.Post(this.urlApi2 + 'users/delete_account', {email: email});
    };

    getFavouriteDetails(order_id, loader = true) {
        this.requestMessageIdentifier = 'getFavouriteDetails';
        return this.Post(this.urlApi2 + 'whatsapp/get_favourite_data', {order_id: order_id}, loader);
    };

}






