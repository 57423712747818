import {Injectable} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {Storage} from '@ionic/storage';

import { Platform } from '@ionic/angular';

import { Bugfender } from '@bugfender/sdk';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    user = null;
    user_id = 0;
    type = 'public'; // public / client / merchant / merchantBarista
    token = null;

    constructor(private modalController: ModalController, private storage: Storage, public platform: Platform) {
        console.log('inside auth service');
        this.storage.get('accessToken').then((token) => {
            this.token = token;
        });
    }

    async updateState() {
      const accessToken = await this.storage.get('accessToken');
      return await this.storage.get('user').then((user) => {
        if (user) {
          this.user = user;
        } else {
          this.user = null;
        }
        if (accessToken) {
          this.token = accessToken;
        } else {
          this.token = null;
        }
        return true;
      });
    }

    isLoggedIn() {
        return this.token !== null && this.token.length > 5;
    }

    isMerchant() {
        return (this.type == 'merchant' || this.type == 'merchantBarista');
    }

    // async showLoginModal() {
    //     const modal = await this.modalController.create({
    //         component: LoginComponent,
    //         // cssClass: 'my-custom-class'
    //     });
    //     return await modal.present();
    // }

    async getLoggedInUser() {
      let loggedInUser = null;
      return await this.storage.get('accessToken').then((token) => {
          if (token) {
            return this.storage.get('user').then((user) => {
              if (user) {
                loggedInUser = user;

                // Bugfender.setDeviceKey('user_email', user.profile.email);
                // Bugfender.log("USER");
                // Bugfender.log(user);

              }
              return loggedInUser;
            });
          }
          return loggedInUser;
      });
    }

    showRegister() {

    }

    showResetPassword() {

    }

    login() {

    }

    logout() {

    }
}
