import {Component, OnDestroy, OnInit} from '@angular/core';
import {NavController} from '@ionic/angular';
import {Storage} from '@ionic/storage';
import { Subscription } from 'rxjs';

import {Events} from '../../../services/events.service';

@Component({
    selector: 'app-checkout-bar',
    templateUrl: './checkout-bar.component.html',
    styleUrls: ['./checkout-bar.component.scss'],
})
export class CheckoutBarComponent implements OnInit, OnDestroy {

    total: number = 0;


    private addToCartSub: Subscription;
    private refreshCartSub: Subscription;

    constructor(private events: Events, private storage: Storage, private nav: NavController) {
        //add to cart event
        this.addToCartSub = this.events.subscribe('addToCart', (cartItem) => {
            console.log('fired');
            console.log(cartItem);
            // cartItem.product;
            // cartItem.quantity;
            // cartItem.total;
            // cartItem.options;

            this.storage.get('cart').then((cart: Array<Object>) => {

                let newCart = [];
                if (cart) {
                    newCart = cart;
                }
                newCart.push(cartItem);

                this.storage.set('cart', newCart).then(() => {
                    this.refreshCart();
                });

            });
        });

        this.refreshCartSub = this.events.subscribe('refreshCart', () => {
            this.refreshCart()
        })

    }

    ngOnInit() {
        this.refreshCart();

    }

    refreshCart() {
        this.storage.get('cart').then((cart: Array<Object>) => {

            this.storage.get('merchant').then((merchant) => {
                this.total = 0;
                if (cart && cart.length > 0) {
                    let merchants = {}
                    let newCart = []
                    cart.map((lineItem: any) => {
                        console.log(lineItem)
                        merchants[lineItem.merchant.id] = 1;
                        if (lineItem.merchant.id == merchant.id) {
                            this.total += lineItem.total;
                            newCart.push(lineItem)
                        }
                    });
                    if (Object.keys(merchants).length>1){
                        this.storage.set('cart',newCart)
                    }
                }
            });

        });
    }

    checkout() {
        // this.nav.navigateForward(['/u/checkout']);
        this.nav.navigateRoot('/u/checkout');
    }

    ngOnDestroy(): void {
        this.addToCartSub.unsubscribe();
        this.refreshCartSub.unsubscribe();
    }

}
