import {Component} from '@angular/core';
import {Events} from '../services/events.service';
import { Router, RouterEvent } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-u',
    templateUrl: 'u.page.html',
    styleUrls: ['u.page.scss']
})
export class UPage {
    title: string = 'Order';
    hidden = false;
    showFiltersOnHeader = false;
    showSearchBar = false;
    selectedTab = '';

    constructor(
        private events: Events,
        private router:Router
    ) {

        this.router.events.subscribe((event: RouterEvent) => {
          if(event && event.url){
            this.selectedTab = event.url;
          }
        });

        this.events.subscribe('user:title', (newTitle) => {
            this.tabsChanged(newTitle);
        });

        this.events.subscribe('user:profile_complete',(profile_complete)=>{
            // this.hidden = !profile_complete;
        })

        this.events.subscribe('user-nav::show-filters::show-searchbar', ([showFiltersOnHeader, showSearchBar]) => {
            this.showFiltersOnHeader = showFiltersOnHeader;
            this.showSearchBar = showSearchBar;
        });
    }

    ngOnInit() {
        this.title = 'Order';
        // this.hidden = true
    }

    tabsChanged(title) {
        this.title = title;
    }

    ngOnDestroy() {
        // this.events.unsubscribe('user:title');
        // this.events.unsubscribe('user:profile_complete');
        // this.events.unsubscribe('user-nav::show-filters::show-searchbar');
    }

}
