import {Component, NgZone, OnInit, Input, OnDestroy} from '@angular/core';
import {AlertController, ModalController} from '@ionic/angular';
import {ReviewModalComponent} from "../review-modal/review-modal.component";
import {ApiService} from "../../../services/api.service";
import {CancelModalComponent} from "../cancel-modal/cancel-modal.component";
import {AppRate} from '@ionic-native/app-rate/ngx';
import { InAppBrowser, InAppBrowserOptions } from '@ionic-native/in-app-browser/ngx';
import {encode} from 'punycode';
import { Router } from '@angular/router';
import { OrderStatuses } from '../../../common/order-statuses';
import { Device } from '@ionic-native/device/ngx';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import {Storage} from '@ionic/storage';

import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';

import { AngularFireAuth } from '@angular/fire/compat/auth';

import {Events} from '../../../services/events.service';

import { BehaviorSubject, Subscription } from 'rxjs';

// Define the OrderData interface
interface OrderData {
    id: number;
    user_id: number | string;
    merchant: number | string;
    [key: string]: any;
}


@Component({
    selector: 'app-order-modal',
    templateUrl: './order-modal.component.html',
    styleUrls: ['./order-modal.component.scss'],
})
export class OrderModalComponent implements OnInit, OnDestroy {

    order: any;
    orderStatuses = OrderStatuses.ORDER_STATUSES;
    deliveryFee = 15;

    private authSubscription: Subscription;

    @Input() order$: BehaviorSubject<OrderData | null>;
    currentOrder: OrderData; // Renamed to avoid naming conflict
    private subscription: Subscription;

    constructor(public modalController: ModalController,
                private events: Events,
                private zone: NgZone,
                private apiService: ApiService,
                private alert: AlertController,
                private appRate: AppRate,
                private iab: InAppBrowser,
                private router: Router,
                private firestore: AngularFirestore,
                private afAuth: AngularFireAuth,
                private device: Device,
                private appVersion: AppVersion,
                private storage: Storage) {
    }

    ngOnInit() {
      this.authSubscription = this.afAuth.authState.subscribe(user => {
          if (user) {

          } else {
              console.error('User not authenticated');
              // Handle unauthenticated state, e.g., redirect to login
          }
      });

      this.subscription = this.order$.subscribe(order => {
          if (order) {
              this.order = order;
              console.log('Order updated:', this.order);

              const host = window.location.hostname;
              const collectionPath = (host === 'zapapp-dev.coffeemonster.co.za' || host === 'localhost')
                  ? 'zapper/stores/orders'
                  : 'zapahead_live/stores/orders';

              const orderDocRef = this.firestore.collection(collectionPath).doc(this.order.id.toString());

              console.time('Firestore Document Listener');
              orderDocRef.snapshotChanges().subscribe(docSnapshot => {
                  console.timeEnd('Firestore Document Listener');

                  if (docSnapshot.payload.exists) {
                      const orderData = docSnapshot.payload.data() as OrderData;

                      // Update this.order.status with the latest status from Firestore
                      this.order.status = orderData.status;
                  } else {
                      console.log("Order document does not exist.");
                  }
              });

          }
      });

			// this.getOrdersFromFirebase();

      this.storage.get('deliveryFee').then((value) => {
        if(value) {
          this.deliveryFee = value;
        }
      });
    }

    ngOnDestroy() {
        // Unsubscribe to avoid memory leaks
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }


    async closeModal() {
        this.events.publish('closeOrderModal', {})
        await this.modalController.dismiss();
    }


    imHere(order) {
        //Alert
        //Are you sure ?
        this.alert.create({
            header: 'I\'m Here',
            message: 'Ready for your order?',
            buttons: [
                {
                    text: 'OK',
                    handler: () => {
                        this.apiService.imHere(order).then((data: any) => {
                            this.events.publish('getOrders', {});
                            this.closeModal()
                        });
                    }
                }
                , {
                    text: 'Cancel',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: (a) => {
                    }
                }
            ]

        }).then((al) => {
            al.present();
        });
    }

    async rateMerchant(order) {
        const modal = await this.modalController.create({
            component: ReviewModalComponent,
            componentProps: {
                'order': order,
            }
        });

        modal.onDidDismiss()
          .then((orderId) => {
            this.router.navigate(['/u/orders'],  { queryParams: { orderId: orderId.data } });
          });

        return await modal.present();
    }

    async openCancel(order) {
        const modal = await this.modalController.create({
            component: CancelModalComponent,
            componentProps: {
                'order': order,
            }
        });
        return await modal.present();
    }

    range(min, max, step) {
        step = step || 1;
        var input = [];
        for (var i = min; i <= max; i += step) {
            input.push(i);
        }
        return input;
    }


    takeMeThere(order) {
        console.log(order);
        window.location.href = `https://www.google.com/maps/dir/?api=1&destination=${order.merchant.location.lat},${order.merchant.location.lng}`;
    }

    rateApp(order) {
        let preferences = {
            displayAppName: 'Coffee Monster',
            usesUntilPrompt: 0,
            promptAgainForEachNewVersion: false,
            inAppReview: true,
            data: {
                'api' :  this.apiService,
                'order': this.order,
                'alert' : this.alert,
                'events': this.events,
                'close' : this.closeModal()
            },
            storeAppURL: {
                ios: 'com.dawpro.moco',
                android: 'market://details?id=com.dawpro.moco'
            },
            customLocale: {
                title: "Would you mind rating %@?",
                message: "It won’t take more than a minute and helps to promote our app. Thanks for your support!",
                cancelButtonLabel: "No thanks",
                laterButtonLabel: "Remind me later",
                rateButtonLabel: "Rate it now",
                yesButtonLabel: "Yes!",
                noButtonLabel: "Not really",
                appRatePromptTitle: 'Do you like using %@',
                feedbackPromptTitle: 'Mind giving us some feedback?',
            },
            callbacks: {
                onRateDialogShow: function (callback) {
                    console.log('User Prompt for Rating');
                    console.log(callback);
                },
                onButtonClicked: function (buttonIndex) {
                    console.log('Selected Button Index', buttonIndex);
                    if (buttonIndex == 3) {
                        //They clicked the rating button
                        preferences.data.api.rateAppOnce({order_id: preferences.data.order.id}).then(function (response) {
                        })
                    }
                }
            },
            openUrl: (url: string) => {
                // return url;
                // console.log(url)
                window.location.href=url;
                // window.open(encodeURI(url), '_blank', 'location=yes')
                // this.iab.create(url, '_blank', 'location=yes');
            }

        }
        this.appRate.preferences = preferences;

        this.appRate.promptForRating(true);
    }

    showImHereButton(order) {
      let shortStatus = order.shortStatus ? order.shortStatus.toLowerCase() : null;

      return order.im_here == false && (shortStatus == 'merchant_viewed' || shortStatus == 'should_process'
              || shortStatus.toLowerCase() == 'in_progress' || shortStatus == 'ready_for_collection')
              && order.delivery_method !== 'Deliver to Me';
    }

    showCancelButton(order) {
      let shortStatus = order.shortStatus ? order.shortStatus.toLowerCase() : null;

      if(shortStatus == 'processing') {
        return true;
      }

    }

    openWhatsapp(order) {
      let version = null;
      let orderStatus = this.orderStatuses[order.shortStatus.toLowerCase()] ?
                        this.orderStatuses[order.shortStatus.toLowerCase()].longLabel
                        : order.shortStatus.split('_').join(' ');
      this.storage.get('user').then((user) => {
        // const userMail = user.profile.email;
        // version = versionNumber;
        const text = 'Hi%20I%20have%20a%20question%20relating%20to%20Order%20'
                      + order.id;

        // window.open('https://wa.me/+27768125904/?text=' + text, '_blank');

        // window.location.href = 'https://wa.me/27768125904?text=' + text;
        window.open('https://wa.me/27768125904?text=' + text, "_system")
      });
    }

}
