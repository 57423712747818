import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {UPage} from './u.page';
import {AuthGuardService} from '../services/auth-guard.service';

const routes: Routes = [
    {
        path: '',
        redirectTo: '/u/home',
        pathMatch: 'full'
    },
    {
        path: 'u',
        component: UPage,
        children: [
            {
                path: '',
                redirectTo: 'home',
                pathMatch: 'full'
            },
            {
                path: 'home',
                loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
            },
            {
                path: 'profile',
                loadChildren: () => import('./profile/profile.module').then(m => m.ProfilePageModule),
            },
            {
                path: 'my-favourites',
                loadChildren: () => import('./my-favourites/my-favourites.module').then(m => m.MyFavouritesPageModule)
            },
            {
                path: 'monster-spot',
                loadChildren: () => import('./monster-spot/monster-spot.module').then(m => m.MonsterSpotPageModule)
            },
            {
                path: 'orders',
                loadChildren: () => import('./orders/orders.module').then(m => m.OrdersPageModule)
            },
            {
                path: 'manage-cards',
                loadChildren: () => import('./manage-cards/manage-cards.module').then(m => m.ManageCardsPageModule)
            },
            {
                path: 'contact-us',
                loadChildren: () => import('./contact-us/contact-us.module').then(m => m.ContactUsPageModule)
            },
            {
                path: 'rewards',
                loadChildren: () => import('./rewards/rewards.module').then(m => m.RewardsPageModule)
            },
            {
                path: 'address-book',
                loadChildren: () => import('./address-book/address-book.module').then(m => m.AddressBookPageModule)
            },
            {
                path: 'gift-cards',
                loadChildren: () => import('./gift-cards/gift-cards.module').then( m => m.GiftCardsPageModule)
            },
        ]
    },
    {
        path: 'checkout',
        loadChildren: () => import('./checkout/checkout.module').then(m => m.CheckoutPageModule)
    },

];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class URoutingModule {
}
