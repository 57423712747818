import {Injectable} from '@angular/core';
import {Storage} from '@ionic/storage';
import {AlertController, ModalController} from '@ionic/angular';
import {forEach} from '@angular-devkit/schematics';
// import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';

import {Events} from '../services/events.service';

import { Toast } from '@capacitor/toast';

@Injectable({
    providedIn: 'root'
})
export class CartService {
    cart;
    currentLimit = 0;
    currentlyHave = 0;
    currentProduct;
    currentCategory;
    currentSubtotal = 0;
    currentTotal = 0;
    currentQuantity = 1;
    currentCartItem = {};
    currentCheckboxData = {};
    merchant: any;

    constructor(private storage: Storage,
                private events: Events,
                private alert: AlertController,
                private modalController: ModalController
                ) {

    }

    load(product, category, merchant) {
        this.merchant = merchant;
        this.currentLimit = 0;
        this.currentlyHave = 0;
        this.currentProduct = product;
        this.currentCategory = category;
        this.currentCategory.currentlyHave = 0;
        this.currentCartItem = {};
        this.currentCheckboxData = {};
        this.currentQuantity = 1;
        return this.storage.get('cart').then((cart) => {
            this.cart = cart;
            if (this.cart && this.cart.length > 0) {
                this.cart.map((cartRow) => {
                    if (cartRow.category.name == this.currentCategory.name) {
                        this.currentlyHave += cartRow.quantity;
                        this.currentCategory.currentlyHave += cartRow.quantity;
                        console.log("ADDING 1");
                    }
                });
            }

            if (this.currentCategory.limit) {
                this.currentLimit = this.currentCategory.limit;
            }
        });
    }

    // async saveCart() {
    //     return await this.storage.set('cart', this.cart).then(() => {
    //         return true;
    //     });
    // }

    quickAdd(product) {
        // this.firebaseAnalytics.logEvent('add_to_cart', {});
        this.currentCartItem = {};

        if (product.simple_product) {
            this.currentCartItem[product.name] = {
                name: '',
                price: product.simple_product_price
            };

        } else {
            product.variations.forEach((variation) => {
                variation.options.forEach((option) => {
                    if ((variation.single && variation.options.length == 1) || option.is_default) {
                        this.currentCartItem[variation.name] = option;
                    }
                });
            });
        }
        this.currentQuantity = 1;
        if (this.currentCategory.limit == 0 || this.currentlyHave <= this.currentCategory.limit) {
            this.calculateCartItem();
            this.addToCart();

            if(this.currentCategory.currentlyHave == this.currentCategory.limit) {
              product.currentQuantity = this.currentCategory.currentlyHave
            } else {
              product.currentQuantity = this.currentCategory.currentlyHave+1
            }


        } else {
            this.showLimitMessage();
        }
    }

    plus() {
        console.log("CATEGORY LIMIT")
        console.log(this.currentCategory.limit);

        console.log("CURRENTLY HAVE");
        console.log(this.currentlyHave);

        console.log("CURRENTLY HAVE CATEGORY");
        console.log(this.currentCategory.currentlyHave);

        console.log("CURRENT QUANTIYTY");
        console.log(this.currentQuantity);

        if (this.currentCategory.limit == 0 || ((this.currentCategory.currentlyHave + this.currentQuantity) +1) <= this.currentCategory.limit) {
            this.currentQuantity++;
            this.calculateCartItem();
        } else {
            this.showLimitMessage();
        }
    }

    minus() {
        if (this.currentQuantity !== 0) {
            this.currentQuantity--;
            this.calculateCartItem();
        }
    }

    showLimitMessage() {
        let extraMessage = '';
        if (this.currentCategory.currentlyHave > 0) {
            extraMessage = 'You currently have ' + this.currentCategory.currentlyHave + ' in your basket';
        }

        this.alert.create({
            header: 'Limit reached',
            message: 'This product category has a limit of ' + this.currentLimit + ' items per order. ',
            buttons: ['OK']
        }).then((al) => {
            al.present();
        });
    }

    optionChanged(e, variation) {
        this.currentCartItem[variation.name] = e.target.value;
        this.calculateCartItem();

        console.log('VALUE');
        console.log(e.target.value);
    }

    optionChangedDefault(name, value) {
        this.currentCartItem[name] = value;
        this.calculateCartItem();

        console.log('VALUE');
        console.log(value);
    }

    checkChanged(e, variation, option) {
        if (!this.currentCheckboxData[variation.name]) {
            this.currentCheckboxData[variation.name] = [];
        }
        let wasChecked = e.target.checked;
        if (wasChecked == false) {
            //add it
            this.currentCheckboxData[variation.name].push(option);
        } else {
            //remove it
            let newData = [];
            this.currentCheckboxData[variation.name].map((row) => {
                if (row.name !== option.name) {
                    newData.push(row);
                }
            });
            this.currentCheckboxData[variation.name] = newData;

        }
        this.calculateCartItem();
    }

    showError(errorMsg) {
        this.alert.create({
            header: 'Error',
            message: errorMsg,
            buttons: ['OK']
        }).then((al) => {
            al.present();
        });
        return false;
    }

    validate() {
        if (this.currentQuantity == 0) {
            return this.showError('Please select a Quantity')
        }
        if (this.currentProduct.simple_product) {
            return true;
        }

        let countRadios = 0
        this.currentProduct.variations.forEach((variation)=>{
            if (variation.single){
                countRadios++
            }
        })

        if (Object.keys(this.currentCartItem).length !== countRadios){
            return this.showError('Please select the required options')
        }

        return true;
    }

    addToCart() {
        // this.firebaseAnalytics.logEvent('add_to_cart', {});

        if (this.currentCategory.limit == 0 || this.currentCategory.currentlyHave >= this.currentCategory.limit) {
            this.showLimitMessage();
            console.log("OVER LIMIT")
            return false;
        }

        let productData = {};
        productData['options'] = {};

        Object.keys(this.currentCartItem).forEach((key) => {
            productData['options'][key] = this.currentCartItem[key];
        });
        Object.keys(this.currentCheckboxData).forEach((key) => {
            productData['options'][key] = [];
            this.currentCheckboxData[key].map((row) => {
                productData['options'][key].push(row);
            });
        });

        productData['quantity'] = this.currentQuantity;
        productData['product'] = this.currentProduct;
        productData['category'] = this.currentCategory;
        productData['subTotal'] = this.currentSubtotal;
        productData['total'] = this.currentTotal;
        productData['merchant'] = this.merchant;


        this.events.publish('addToCart', productData);

        // console.log("Showing toast");
        // Toast.show({
        //   text: 'Added to cart',
        //   position: 'center',
        // });

        return true;

    }

    calculateCartItem() {
        this.currentTotal = 0;
        Object.keys(this.currentCartItem).forEach((key) => {
            this.currentTotal += parseFloat(this.currentCartItem[key].price);
        });

        Object.keys(this.currentCheckboxData).forEach((key) => {
            Object.keys(this.currentCheckboxData[key]).forEach((optionName) => {
                if (this.currentCheckboxData[key][optionName] !== false) {
                    this.currentTotal += parseFloat(this.currentCheckboxData[key][optionName].price);
                }
            });
        });
        this.currentSubtotal = this.currentTotal;
        this.currentTotal = this.currentTotal * this.currentQuantity;
    }
}
