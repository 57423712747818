import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController, NavParams } from '@ionic/angular';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Component({
    selector: 'app-address-modal',
    templateUrl: './address-modal.component.html',
    styleUrls: ['./address-modal.component.scss'],
})
export class AddressModalComponent implements OnInit {

    @ViewChild('addressRef', {read: false, static: false}) addressRef: GooglePlaceDirective;
    addressText: string;
    addressForm: FormGroup;
    isAdhoc: boolean = false;

    constructor(
        private form: FormBuilder,
        private modalController: ModalController,
        private navParams: NavParams,
        private afAuth: AngularFireAuth,
        private firestore: AngularFirestore
    ) { }

    ngOnInit() {
        if (this.navParams.get('isAdhoc') === true) {
            this.isAdhoc = true;
            this.addressForm = this.form.group({
                location: [null, [Validators.required]],
                address: [null, [Validators.required]],
            });
        } else {
            this.addressForm = this.form.group({
                name: [null, [Validators.required]],
                location: [null, [Validators.required]],
                address: [null, []],
            });
        }
    }

    async closeModal() {
        await this.modalController.dismiss();
    }

    async submit() {
        console.log(this.addressForm.value);
        try {
            const user = await this.afAuth.currentUser;
            if (user) {
                // Determine the Firestore path based on the host
                const host = window.location.hostname;
                const collectionPath = (host === 'zapapp-dev.coffeemonster.co.za' || host === 'localhost')
                    ? 'zapper/users/profiles'
                    : 'zapahead_live/users/profiles';

                const userRef = this.firestore.doc(`${collectionPath}/${user.uid}`);

                // Fetch the current user document
                const userDoc = await userRef.get().toPromise();

                // Explicitly type the userData to include addresses
                let existingAddresses: any[] = [];

                if (userDoc.exists) {
                    const userData = userDoc.data() as { addresses?: any[] };
                    existingAddresses = userData.addresses || [];  // Get existing addresses or initialize an empty array
                }

                // Add the new address to the array
                existingAddresses.push(this.addressForm.value);

                // Update the user's document with the new array of addresses
                await userRef.update({
                    addresses: existingAddresses
                });

                console.log('Address added successfully to Firestore');
                this.modalController.dismiss(this.addressForm.value);
            } else {
                console.error('User not authenticated');
                this.modalController.dismiss(); // Optionally close the modal if there's an error
            }
        } catch (error) {
            console.error('Error saving address to Firestore:', error);
            this.modalController.dismiss(); // Optionally close the modal if there's an error
        }
    }

    addressChange(e) {
        this.addressForm.patchValue({
            location: {
                address: e.formatted_address,
                lat: e.geometry.location.lat(),
                lng: e.geometry.location.lng()
            },
        });
        this.addressText = e.formatted_address;
    }
}
