import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';
import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {IonicStorageModule} from '@ionic/storage';
import {FormsModule} from '@angular/forms';
import {environment} from '../environments/environment';
import {HttpClientModule} from '@angular/common/http';
import {ApiService} from './services/api.service';
import {Device} from '@ionic-native/device/ngx';
import {DatePicker} from '@ionic-native/date-picker/ngx';
// import {Geolocation} from '@ionic-native/geolocation/ngx';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';
import { Crop } from '@ionic-native/crop/ngx';
import {FirebaseX} from '@awesome-cordova-plugins/firebase-x/ngx';
import {Facebook} from '@ionic-native/facebook/ngx';
import {InAppBrowser} from '@ionic-native/in-app-browser/ngx';
import {Camera} from '@awesome-cordova-plugins/camera/ngx';
import {TermsModule} from './public/terms/terms.module';
import {TermsComponent} from './public/terms/terms.component';
import {ComponentsModule} from './u/components/components.module';
import {UModule} from './u/u.module';
import {GooglePlaceModule} from 'ngx-google-places-autocomplete';
import {AngularFirestoreModule} from '@angular/fire/compat/firestore';

import { AngularFireModule } from '@angular/fire/compat';

// import { NgxClarityModule } from 'ngx-clarity';

import {Network} from '@ionic-native/network/ngx';
import {HTTP} from '@awesome-cordova-plugins/http/ngx';
import {Insomnia} from '@ionic-native/insomnia/ngx';
import {Media} from '@ionic-native/media/ngx';
import {File} from '@awesome-cordova-plugins/file/ngx';
import {SocialSharing} from '@ionic-native/social-sharing/ngx';
import {Deeplinks} from '@ionic-native/deeplinks/ngx';
import {AppRate} from '@ionic-native/app-rate/ngx';
import {BackgroundMode} from '@ionic-native/background-mode/ngx';
import {LocalNotifications} from '@ionic-native/local-notifications/ngx';
import {AuthService} from './services/auth.service';
import {AuthGuardService} from './services/auth-guard.service';
import {SignInWithApple} from '@ionic-native/sign-in-with-apple/ngx';
import {CoffeeShopFiltersService} from './services/coffee-shop-filters.service';
import {OnResumeAppService} from './services/on-resume-app.service';
// import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';

@NgModule({
    declarations: [AppComponent],
    entryComponents: [
        TermsComponent,
        // UserNavComponent
    ],
    imports: [
        // NgxClarityModule.forRoot({
        //   enabled: true,
        //   projectId: 'h4cb5h05z9',
        // }),
        IonicStorageModule.forRoot({
            name: 'cmdb',
            driverOrder: ['sqlite', 'websql', 'indexeddb']
            // driverOrder: ['indexeddb', 'websql', 'localstorage']
        }),
        BrowserModule,
        IonicModule.forRoot({
            mode: 'md'
        }),
        FormsModule,
        AppRoutingModule,
        HttpClientModule,
        TermsModule,
        ComponentsModule,
        UModule,
        GooglePlaceModule,
        AngularFireModule.initializeApp(environment.firebase),

        AngularFirestoreModule,

        // AngularFireAuthModule,
    ],
    providers: [
        StatusBar,
        SplashScreen,
        HttpClientModule,
        ApiService,
        Device,
        DatePicker,
        // Geolocation,
        Diagnostic,
        Crop,
        FirebaseX,
        Facebook,
        Camera,
        InAppBrowser,
        Network,
        HTTP,
        File,
        Insomnia,
        BackgroundMode,
        Media,
        SocialSharing,
        Deeplinks,
        AppRate,
        LocalNotifications,
        AuthService,
        AuthGuardService,
        SignInWithApple,
        CoffeeShopFiltersService,
        OnResumeAppService,
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
        // FirebaseAnalytics,
        AppVersion
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
